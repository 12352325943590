import { Account, AccountLevel } from "../account/types";
import { Permission } from "../permission/api";

export interface UserListItem {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userLevel: UserLevel;
  roleName?: string;
  confirmed: boolean;
  active: boolean;
  accounts?: UserAccount[];
}

export interface UserAccount {
  id: string;
  code: string;
  name: string;
  level: AccountLevel;
}

export interface User {
  ssoId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber?: string;
  role?: string;
  userLevel: number;
  permissions?: Permission[];
  parentAccount?: string;
  state?: UserState;
  emailConfirmed?: boolean;
  accounts?: Account[];
}

export interface CognitoUser {
  ssoId: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber?: string;
  emailConfirmed: boolean;
}

export enum UserLevel {
  Account = "ACCOUNT",
  SubAccount = "SUB_ACCOUNT",
  Asset = "ASSET",
}

export const userLevelLabels: { [key in UserLevel]: string } = {
    [UserLevel.Account]: "Account",
    [UserLevel.SubAccount]: "Sub Account",
    [UserLevel.Asset]: "Individual",
}

export enum UserState {
  Active = "ACTIVE",
  Disabled = "DISABLED",
}

export interface GetUsersResponse {
  content: UserListItem[];
  number: number;
  size: number;
  totalElements: number;
}

export interface RegisterUserParams {
  accountCode: string;
  email: string;
  reCaptchaToken: string;
}
