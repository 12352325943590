import { CognitoUser, UserListItem } from "./types";
import { AttributeType } from "@aws-sdk/client-cognito-identity-provider/dist-types/models/models_0";
import { AccountLevel } from "../account/types";

export const getFullName = (u: any): string => {
  let f = u?.firstName || "";
  if (u?.lastName) {
    f = f + " " + u.lastName;
  }
  return f;
};

export const getAssignedAccountsCodeList = (user: UserListItem, max?: number, join: string = ", ") => {
  return user?.accounts
    ?.map((account: any) => {
      return account?.code;
    })
    ?.filter((val, i) => val && (max ? i < max : true))
    ?.join(join);
};

export const getAssignedAccountsCodeNameList = (user: UserListItem, join: string = ", ") => {
  return user?.accounts
    ?.map((account) => {
      if (account.level === AccountLevel.Individual) return account?.code;
      return [account?.code, account?.name]?.filter((val) => val)?.join(" - ");
    })
    ?.join(join);
};

const attributeMap: { [key: string]: keyof CognitoUser } = {
  "custom:user_id": "ssoId",
  given_name: "firstName",
  family_name: "lastName",
  email: "email",
  email_verified: "emailConfirmed",
  phone_number: "contactNumber",
};

export const mapToCognitoUser = (attributes?: AttributeType[]): CognitoUser => {
  let cognitoUser: CognitoUser = {
    ssoId: "",
    firstName: "",
    lastName: "",
    email: "",
    emailConfirmed: false,
    contactNumber: "",
  };

  attributes?.forEach((attr) => {
    if (attr.Name) {
      const prop = attributeMap[attr.Name];

      if (prop) {
        if (prop === "emailConfirmed") {
          cognitoUser[prop] = attr.Value === "true";
        } else {
          // @ts-ignore
          cognitoUser[prop] = attr.Value;
        }
      }
    }
  });

  return cognitoUser;
};
